<template>

  <div class="grid">
    <div class="col-12">
      <div id="overlay" v-if="overlay"></div>

      <div class="card">
        <div class="flex justify-content-between">
          <div class="bloc1"><h5>Anular Ticket ({{ stats.count }})</h5></div>

          <div class="bloc2"><h3>Total:
            {{ stats.mmonto_aceptado ? formatCurrency(Number(stats.mmonto_aceptado)) : formatCurrency(0) }}</h3></div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-2">
      <div class="card p-fluid" id="number-container">
        <Filter
            @get-data="loadLazyData"
            page="cancel"/>
      </div>
    </div>

    <div class="col-12 lg:col-10">
      <div class="card">
        <div>
          <DataTable
              :value="tickets" :lazy="true" :paginator="true" :rows="10" v-model:filters="filters" ref="dt"
              dataKey="id"
              class="p-datatable-gridlines p-datatable-striped p-datatable-sm"
              :totalRecords="stats.count" :loading="loadingTable" @page="onPage($event)" @sort="onSort($event)"
              responsiveLayout="scroll"
              v-model:selection="selectedAgencies" :selectAll="selectAll" @select-all-change="onSelectAllChange"
              @row-select="onRowSelect" @row-unselect="onRowUnselect"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[10,20,50]" #
              currentPageReportTemplate="{first} al {last} de {totalRecords}">

            <Column field="tticket" header="Ticket" :sortable="true"
                    bodyStyle="text-align: center; width: 120px !important;">
              <template #body="{ data}">
                <Button
                    class="t-tickets"
                    type="button"
                    :style="getCancelColour(data.mmonto_aceptado)"
                    :label="data.tticket"
                    @click="OpenViewTicket(data)"
                />
              </template>
            </Column>

            <Column field="hora" header="Hora" :sortable="true" bodyStyle="text-align: center">
              <template #body="{data}">
                {{ data.hora }}
              </template>
            </Column>
            <Column field="dtransaccion" header="Fecha" :sortable="true" bodyStyle="text-align: center" footerStyle="text-align: right">>
              <template #body="{data}">
                {{ formatDate(data.dtransaccion) }}
              </template>
              <template #footer>
                Total:
              </template>
            </Column>
            <!--              <Column field="tproductoxsorteo" header="Sorteo" :sortable="true" bodyStyle="text-align: left"/>-->
            <Column field="mmonto_aceptado" header="Monto" :sortable="true"
                    bodyStyle="text-align: right"
                    footerStyle="text-align: right">
              <template #footer>
                {{ stats.mmonto_aceptado ? formatCurrency(Number(stats.mmonto_aceptado)) : formatCurrency(0) }}
              </template>
            </Column>
            <Column field="tcomercializador" header="Comercializador" :sortable="true" bodyStyle="text-align: left"/>
            <Column field="tdistribuidor" header="Distribuidor" :sortable="true" bodyStyle="text-align: left"/>
            <Column field="tagencia" header="Punto de Venta" :sortable="true" bodyStyle="text-align: left"/>
            <Column header="Estatus" :sortable="true" field="tstatus" bodyStyle="text-align: left;">
              <template #body="{data}">
                <div :class="statusClass(data)">
                  {{ getStatus(data) }}
                </div>
              </template>
            </Column>

          </DataTable>
        </div>
      </div>
    </div>
  </div>

  <ViewDialog
      :show="displayTicket"
      :ticket="ticket"
      page="cancel"
      @close="closeViewTicketDialog"/>
</template>

<script>
import TicketService from "@/service/backend/TicketService";
import Filter from "@/components/Filters/Filter";
import generalMixin from "@/mixins/generalMixin";
import {FilterMatchMode} from "primevue/api";
import ViewDialog from "@/components/Tickets/Dialogs/ViewDialog";

export default {
  mixins: [generalMixin],
  components: {
    'Filter': Filter,
    'ViewDialog': ViewDialog,
  },
  data() {
    return {
      loadingTable: false,
      totalRecords: 0,
      tickets: null,
      selectedAgencies: null,
      selectAll: false,
      filters1: null,
      lazyParams: {},
      stats: {count: 0, sum: 0},
      options: {},
      max: 0,
      displayTicket: false,
      ticket: {},
      overlay: false,
    }
  },
  agencyService: null,
  created() {
    this.ticketService = new TicketService();
    this.initFilters();
  },
  mounted() {
    this.loadingTable = false;
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
      options: {
        fecha: this.getTodayDate(),
      }
    };
  },
  methods: {
    loadLazyData(filters) {
      this.loadingTable = true;
      this.lazyParams.options = filters ? filters : this.lazyParams.options;
      this.lazyParams.options.cstatus_transaccion = 0; // 0 = VENDIDOS
      this.lazyParams.lista = null; // Get all products

      if (filters != null) {
        setTimeout(() => {
          this.ticketService.getTableDataLazy(this.lazyParams)
              .then(data => {
                    this.tickets = data.rows;
                    this.stats = data.stats;
                    this.max = data.stats.max_aceptado;
                    this.loadingTable = false;
                  }
              );
        }, Math.random() * 1000 + 250);
      }
    },
    onPage(event) {
      this.loadingTable = true;
      const options = this.lazyParams.options;
      this.lazyParams = event;
      this.lazyParams.options = options;
      this.loadLazyData();
    },
    onSort(event) {
      const options = this.lazyParams.options;
      this.lazyParams = event;
      this.lazyParams.options = options;
      this.loadLazyData();
    },
    onFilter() {
      this.lazyParams.filters = this.filters;
      this.loadLazyData();
    },
    onRowSelect() {
      this.selectAll = this.selectedAgencies.length === this.totalRecords
    },
    onRowUnselect() {
      this.selectAll = false;
    },
    getParams(filters) {
      let params = filters ? filters : this.lazyParams;
      params.first = params.first ? params.first : 0;
      params.rows = params.rows ? params.rows : this.$refs.dt.rows;
      params.fecha = params.fecha ? params.fecha : this.getTodayDate();

      return params;
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id_agencia': {value: '', matchMode: 'contains'},
        'tagencia': {value: '', matchMode: 'contains'},
        'dcreacion': {value: '', matchMode: 'contains'},
      }
    },
    async OpenViewTicket(params) {
      params.game = params.animal ? 'animal' : false;
      this.overlay = true;
      this.ticket = await this.ticketService.getTicket(params);
      this.displayTicket = true;
    },
    closeViewTicketDialog() {
      this.displayTicket = false;
      this.overlay = false;
    }

  }
}
</script>
<style>
.t-tickets {
  width: 100%;
  font-weight: bold;
}

</style>
